import {
  SocketEvent,
  FileStatusUpdateEvent,
} from "@/context-providers/SocketProvider/types";
import {
  CompletenessStatus,
  DataProcessingOverviewResponse,
} from "@/hooks/queries";
import { useQueryClient } from "@tanstack/react-query";
import { useCallback } from "react";
import { useSocketEvent } from "./useSocketEvent";
import { useToast } from "@/components/ui";
import { useTranslation } from "react-i18next";
import { processingStatusMap } from "@/components/pages/UploadData/components/processingStatusMap";
import { IconAlertTriangle, IconCircleCheck } from "@tabler/icons-react";

export function useUpdateProcessingStatus(pageSize = 10) {
  const { t } = useTranslation();
  const { toast } = useToast();
  const queryClient = useQueryClient();
  const handleEvent = useCallback(
    ({
      id,
      processingStatus,
      completenessStatus,
      name,
      hasReport,
    }: FileStatusUpdateEvent) => {
      queryClient.setQueryData(
        ["/shipments/files", { limit: pageSize.toString(), offset: "0" }],
        (oldData: DataProcessingOverviewResponse | undefined) => {
          if (!oldData) return;
          const rowToUpdate = oldData.data.find((entry) => entry.id === id);
          const updatedRow = {
            ...rowToUpdate,
            processingStatus,
            completenessStatus,
            hasReport,
          };

          return {
            ...oldData,
            data: oldData.data.map((row) => (row.id === id ? updatedRow : row)),
          };
        },
      );
      if (completenessStatus === CompletenessStatus.Failed) {
        toast({
          description: (
            <div className="flex items-center gap-x-6 text-sm">
              <IconAlertTriangle className="h-8 w-8 shrink-0" />
              <div className="flex flex-col">
                <code>{name}</code>
                <span>
                  {t("common_failed")} -{" "}
                  <span className="font-medium italic">
                    {t(processingStatusMap[processingStatus].tKey)}
                  </span>
                </span>
              </div>
            </div>
          ),
          variant: "error",
        });
      }
      if (completenessStatus !== CompletenessStatus.Successful) {
        return;
      }
      toast({
        description: (
          <div className="flex items-center gap-x-6 text-sm">
            <IconCircleCheck className="h-8 w-8 shrink-0 text-success-foreground" />
            <div className="flex flex-col">
              <code>{name}</code>
              <span>{t("messages_dataImportSuccess")}</span>
            </div>
          </div>
        ),
      });
    },
    [pageSize, queryClient, t, toast],
  );

  useSocketEvent(SocketEvent.PROCESSING_STATUS_UPDATE, handleEvent);
}
