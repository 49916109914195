import {
  Button,
  ButtonSize,
  Popover,
  PopoverClose,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui";
import { cn, formatDateRange } from "@/lib/utils";
import { t } from "i18next";
import { MonthRangePickerPrimitive } from "./MonthRangePickerPrimitive";
import {
  getDateRangeOptions,
  RequiredDateRange,
} from "@/components/layouts/NavigationLayout/dateRangeContext";
import { useMemo } from "react";
import { IconCalendar, IconChevronDown } from "@tabler/icons-react";

interface MonthRangePickerProps {
  size?: ButtonSize;
  className?: string;
  showChevron?: boolean;
  dateRange: RequiredDateRange;
  handleClick: (label: string) => void;
  onApply: ({ from, to }: RequiredDateRange) => void;
}

export function MonthRangePicker({
  size = "default",
  className,
  showChevron = true,
  dateRange,
  handleClick,
  onApply,
}: MonthRangePickerProps) {
  const dateRangeOptions = useMemo(() => getDateRangeOptions(), []);
  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          size={size}
          className={cn("whitespace-nowrap pl-3 text-left", className)}
        >
          <span className="inline-flex items-center">
            <IconCalendar
              stroke={1}
              className={cn("mr-2 h-5 shrink-0", {
                "h-4 w-4": size === "sm",
              })}
            />
            {formatDateRange(dateRange)}
          </span>
          {showChevron && (
            <IconChevronDown className="ml-2 h-5 w-5 opacity-50" />
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[336px] p-0">
        <div className="bg-gray-50 dark:bg-zinc-900">
          <div className="flex flex-wrap justify-center gap-2 px-2 pt-2">
            {dateRangeOptions.map(({ label, tLabel }) => (
              <PopoverClose key={label} asChild>
                <Button
                  size="sm"
                  variant="outline"
                  className="h-6 border bg-gray-100 font-light shadow-none hover:bg-border dark:bg-zinc-800"
                  onClick={() => handleClick(label)}
                >
                  {tLabel ? t(tLabel) : label}
                </Button>
              </PopoverClose>
            ))}
          </div>
          <MonthRangePickerPrimitive
            initialRange={dateRange}
            currentDate={new Date()}
            yearsDisplayed={2}
            onApply={onApply}
          />
        </div>
      </PopoverContent>
    </Popover>
  );
}
